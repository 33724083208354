    @font-face {
        font-family: 'SegoeUI';
        src: url('../assets/fonts/SegoeUI/segoeui.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

        @font-face {
            font-family: 'SegoeUI';
            src: url('../assets/fonts/SegoeUI/segoeuib.ttf') format('truetype');
            font-weight: bold;
            font-style: normal;
        }

