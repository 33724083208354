.a4Nfeq_container {
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: #f0f8ff93;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

._8jeSfa_layout {
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

._8jeSfa_header {
  min-height: 15vh;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
}

._8jeSfa_logo {
  height: auto;
  max-height: 12vh;
}

._8jeSfa_logoutContainer {
  color: #fff;
  text-transform: uppercase;
  background-color: #fe6b03;
  padding: 10px;
}

._8jeSfa_logoutContainer:hover {
  cursor: pointer;
}

._2Lg1uW_container {
  width: 100%;
  color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: row;
  display: flex;
}

._2Lg1uW_columnContainer {
  flex-direction: column;
  flex: 1;
  place-content: center;
  padding: 20px;
}

._2Lg1uW_title {
  text-transform: uppercase;
  color: #d2d7dd;
  text-align: center;
  margin: 0 0 30px;
  font-size: 25px;
  font-weight: bold;
}

._2Lg1uW_elementContainer {
  flex-direction: row;
  align-self: center;
  margin-left: 20px;
  display: flex;
}

._2Lg1uW_textContainer {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 20px;
  display: flex;
}

._2Lg1uW_icon {
  height: 50px;
  width: 50px;
  object-fit: contain;
  align-self: center;
}

._2Lg1uW_textIndicator {
  padding-right: 5px;
  font-size: 40px;
  font-weight: bold;
}

._2Lg1uW_economicImpactContainer {
  width: -moz-fit-content;
  width: fit-content;
  background-color: #fe6b03;
  align-self: center;
  padding: 20px;
}

._2Lg1uW_economicImpactTitle {
  text-transform: uppercase;
  font-size: 22px;
}

._2Lg1uW_economicImpactBoldText {
  text-transform: uppercase;
  margin-right: 10px;
  font-size: 35px;
  font-weight: bold;
}

._2Lg1uW_weatherElementContainer {
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  align-items: flex-end;
  margin-left: 20px;
  display: flex;
}

._2Lg1uW_weatherTextIndicator {
  width: 170px;
  text-align: center;
  align-self: center;
  font-size: 22px;
  font-weight: bold;
}

._2Lg1uW_dayIndicator {
  text-align: center;
  width: 60px;
  background-color: #224f79;
  margin-left: -5px;
  padding: 5px;
  font-size: 12px;
}

._2Lg1uW_weatherStatus {
  padding-left: 90px;
}

.GT_Oza_layout {
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

.GT_Oza_header {
  min-height: 15vh;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
}

.GT_Oza_logo {
  height: auto;
  max-height: 12vh;
}

.GT_Oza_logoutContainer {
  color: #fff;
  text-transform: uppercase;
  background-color: #fe6b03;
  padding: 10px;
}

.GT_Oza_logoutContainer:hover {
  cursor: pointer;
}

.FipinG_layout {
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

.FipinG_header {
  min-height: var(--nav-height);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
}

.FipinG_logo {
  height: 50px;
  cursor: pointer;
}

.uZJm_q_container {
  min-height: var(--body-height);
  background-color: #f5f3f3;
}

.uZJm_q_container .uZJm_q_content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 30px;
  display: flex;
}

.uZJm_q_container .uZJm_q_content .uZJm_q_formContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  display: flex;
}

.uZJm_q_container .uZJm_q_content .uZJm_q_formContainer .uZJm_q_formContent {
  width: 800px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: flex;
}

.uZJm_q_container .uZJm_q_content .uZJm_q_formContainer .uZJm_q_button {
  width: 200px;
  height: 50px;
}

.uZJm_q_container .uZJm_q_content .uZJm_q_formContainer .uZJm_q_rates__title {
  font-weight: bolder;
}

.uZJm_q_container .uZJm_q_content .uZJm_q_formContainer .uZJm_q_rates__grid {
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  display: grid;
}

.CM0Nmq_button {
  color: var(--white);
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 1;
  font-size: var(--font-large);
  border-radius: 5px;
  padding: .5em 0;
  font-weight: bold;
}

.CM0Nmq_button__primary {
  border: 2px solid var(--color-primary);
  background-color: var(--color-primary);
  color: #fff;
}

.CM0Nmq_button__primary:hover, .CM0Nmq_button__primary:focus {
  opacity: .9;
  border: 2px solid var(--color-primary);
  background-color: var(--color-primary);
  color: #fff;
}

.CM0Nmq_button__primaryOutLine {
  border: 2px solid var(--color-primary);
  color: var(--color-primary);
  background-color: #fff;
}

.CM0Nmq_button__primaryOutLine:hover, .CM0Nmq_button__primaryOutLine:focus {
  opacity: .9;
  border: 2px solid var(--color-primary);
  background-color: var(--color-primary);
  color: #fff;
}

.CM0Nmq_button__success {
  color: #fff;
  background-color: #00c3a5;
  border: 2px solid #00c3a5;
}

.CM0Nmq_button__success:hover, .CM0Nmq_button__success:focus {
  opacity: .7;
}

.CM0Nmq_button__successOutLine {
  color: #fff;
  background-color: #00c3a5;
  border: 2px solid #00c3a5;
}

.CM0Nmq_button__successOutLine:hover, .CM0Nmq_button__successOutLine:focus {
  opacity: .7;
}

.CM0Nmq_button__warning {
  color: #fff;
  background-color: orange;
  border: 2px solid orange;
}

.CM0Nmq_button__warning:hover, .CM0Nmq_button__warning:focus {
  opacity: .7;
}

.CM0Nmq_button__danger {
  color: #fff;
  background-color: tomato;
  border: 2px solid tomato;
}

.CM0Nmq_button__danger:hover, .CM0Nmq_button__danger:focus {
  color: #fff;
  background-color: #f97a64;
  border: 2px solid #f97a64;
}

.CM0Nmq_button__dangerOutLine {
  color: tomato;
  background-color: #fff;
  border: 2px solid tomato;
}

.CM0Nmq_button__dangerOutLine:hover, .CM0Nmq_button__dangerOutLine:focus {
  color: #fff;
  background-color: tomato;
  border: 2px solid tomato;
}

.CM0Nmq_button__disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: .3;
}

.CM0Nmq_button__xsmall {
  padding: 0 .5em;
  font-size: 1rem;
}

.CM0Nmq_button__small {
  padding: .5em;
  font-size: 1.4rem;
}

.CM0Nmq_button__medium {
  padding: .5em 0;
  font-size: 2rem;
}

.CM0Nmq_button__large {
  font-size: var(--font-large);
  padding: .5em;
  font-weight: bold;
}

.CM0Nmq_button__adaptable {
  width: 100%;
  height: 100%;
  font-size: 1em;
}

.RqzqJG_header {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  display: flex;
}

.RqzqJG_header .RqzqJG_title {
  width: auto;
  flex-direction: row;
  font-size: 35px;
  display: flex;
}

.RqzqJG_header .RqzqJG_title__bold {
  color: #777;
}

.RqzqJG_header .RqzqJG_title__low {
  color: #999;
}

.RqzqJG_header .RqzqJG_button {
  width: 120px;
  height: 50px;
}

.k9jdba_container {
  min-width: 150px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

.k9jdba_container .k9jdba_label {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.k9jdba_container .k9jdba_label__text {
  justify-content: start;
  align-items: flex-start;
  font-weight: bolder;
  display: flex;
}

.k9jdba_container .k9jdba_label__editIcon {
  width: 20px;
  color: var(--color-primary);
  justify-content: center;
  align-items: center;
  display: flex;
}

.k9jdba_container .k9jdba_label__editIcon:hover {
  opacity: .6;
  cursor: pointer;
}

.k9jdba_container .k9jdba_input {
  color: var(--color-primary);
  width: 100%;
  height: 45px;
  border: none;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}

.bQQ4dW_container {
  min-width: 200px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

.bQQ4dW_container .bQQ4dW_label {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.bQQ4dW_container .bQQ4dW_label__text {
  justify-content: start;
  align-items: flex-start;
  font-weight: bolder;
  display: flex;
}

.bQQ4dW_container .bQQ4dW_label__editIcon {
  width: 20px;
  color: var(--color-primary);
  justify-content: center;
  align-items: center;
  display: flex;
}

.bQQ4dW_container .bQQ4dW_label__editIcon:hover {
  opacity: .6;
  cursor: pointer;
}

.bQQ4dW_container .bQQ4dW_input {
  width: 100%;
  height: 45px;
  border: none;
  border-bottom: 1px solid #e3e3e3;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}

.YkiZsW_container {
  min-width: auto;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.YkiZsW_container .YkiZsW_label {
  width: auto;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.YkiZsW_container .YkiZsW_label__text {
  height: 100%;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  display: flex;
}

.YkiZsW_container .YkiZsW_input {
  width: 100%;
  height: 45px;
  border: none;
  border-bottom: 1px solid #e3e3e3;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}

.Y-ZKha_container {
  min-height: var(--body-height);
  background-color: #f5f3f3;
}

.Y-ZKha_container .Y-ZKha_content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 30px;
  display: flex;
}

.Y-ZKha_container .Y-ZKha_content .Y-ZKha_formContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  display: flex;
}

.Y-ZKha_container .Y-ZKha_content .Y-ZKha_formContainer .Y-ZKha_formContent {
  width: 800px;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  display: grid;
}

.Y-ZKha_container .Y-ZKha_content .Y-ZKha_formContainer .Y-ZKha_button {
  width: 200px;
  height: 50px;
}

.Y-ZKha_container .Y-ZKha_content .Y-ZKha_formContainer .Y-ZKha_rates__title {
  font-weight: bolder;
}

.Y-ZKha_container .Y-ZKha_content .Y-ZKha_formContainer .Y-ZKha_rates__grid {
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  display: grid;
}

.Qm_8Fq_container {
  width: 350px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

.Qm_8Fq_container .Qm_8Fq_label {
  width: 100%;
  justify-content: start;
  align-items: flex-start;
  font-weight: bolder;
  display: flex;
}

.Qm_8Fq_container .Qm_8Fq_input {
  width: 100%;
  height: 45px;
  border: none;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}

.SFX0iG_container {
  min-height: var(--body-height);
  background-color: #f5f3f3;
}

.SFX0iG_container .SFX0iG_content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 30px;
  display: flex;
}

.SFX0iG_container .SFX0iG_content .SFX0iG_formContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  display: flex;
}

.SFX0iG_container .SFX0iG_content .SFX0iG_formContainer .SFX0iG_formContent {
  width: 800px;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  display: grid;
}

.SFX0iG_container .SFX0iG_content .SFX0iG_formContainer .SFX0iG_button {
  width: 120px;
  height: 50px;
}

.NcFQ_G_container {
  min-height: var(--body-height);
  background-color: #f5f3f3;
}

.NcFQ_G_container .NcFQ_G_content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 30px;
  display: flex;
}

.NcFQ_G_container .NcFQ_G_content .NcFQ_G_formContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  display: flex;
}

.NcFQ_G_container .NcFQ_G_content .NcFQ_G_formContainer .NcFQ_G_formContent {
  width: auto;
  flex-direction: column;
  display: flex;
}

.NcFQ_G_container .NcFQ_G_content .NcFQ_G_formContainer .NcFQ_G_formContent__firstGrid {
  width: 100%;
  grid-template-columns: 2fr 1fr 1fr;
  align-self: center;
  gap: 30px;
  display: grid;
}

.NcFQ_G_container .NcFQ_G_content .NcFQ_G_formContainer .NcFQ_G_formContent__secondGrid {
  width: 100%;
  grid-template-columns: 1fr 1fr 2fr;
  align-self: center;
  gap: 30px;
  display: grid;
}

.NcFQ_G_container .NcFQ_G_content .NcFQ_G_formContainer .NcFQ_G_button {
  width: 150px;
  height: 50px;
}

.NcFQ_G_containerModal {
  min-width: 1000px;
  min-height: 350px;
}

.NcFQ_G_containerModal .NcFQ_G_formContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 40px;
  display: flex;
}

.NcFQ_G_containerModal .NcFQ_G_formContainer .NcFQ_G_formContent {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.NcFQ_G_containerModal .NcFQ_G_formContainer .NcFQ_G_formContent .NcFQ_G_tableHeader {
  min-width: 800px;
  grid-template-rows: 50px 10px;
  grid-template-columns: 150px 150px 150px 650px 70px;
  place-items: start center;
  gap: 15px 30px;
  display: grid;
}

.NcFQ_G_containerModal .NcFQ_G_formContainer .NcFQ_G_formContent .NcFQ_G_tableHeader .NcFQ_G_header {
  text-align: center;
  font-weight: bolder;
}

.NcFQ_G_containerModal .NcFQ_G_formContainer .NcFQ_G_formContent .NcFQ_G_tableHeader .NcFQ_G_br {
  width: 100%;
  height: 2px;
  background-color: #ddd;
}

.NcFQ_G_containerModal .NcFQ_G_formContainer .NcFQ_G_formContent .NcFQ_G_tableContent {
  min-width: 800px;
  height: 350px;
  width: max-content;
  grid-template-rows: repeat(auto-fill, 50px);
  grid-template-columns: 150px 150px 150px 650px 70px;
  place-items: start center;
  gap: 30px;
  display: grid;
  overflow-y: scroll;
}

.NcFQ_G_containerModal .NcFQ_G_formContainer .NcFQ_G_formContent .NcFQ_G_tableContent .NcFQ_G_element {
  width: 100%;
  justify-content: flex-start;
  align-self: flex-start;
  align-items: center;
  display: flex;
}

.NcFQ_G_containerModal .NcFQ_G_formContainer .NcFQ_G_button {
  width: 200px;
  height: 50px;
  align-self: center;
  margin-bottom: 20px;
}

.NcFQ_G_containerModal .NcFQ_G_formContainer .NcFQ_G_rates {
  width: auto;
  padding: 0 20px;
}

.NcFQ_G_containerModal .NcFQ_G_formContainer .NcFQ_G_rates__title {
  font-weight: bolder;
}

.NcFQ_G_containerModal .NcFQ_G_formContainer .NcFQ_G_rates__grid {
  grid-template-columns: 100px 100px 100px 100px 100px 100px;
  gap: 10px;
  display: grid;
}

.NcFQ_G_containerModal .NcFQ_G_formContainer .NcFQ_G_deleteRow {
  width: 50px;
  height: 50px;
  align-self: center;
}

.NcFQ_G_containerModal .NcFQ_G_formContainer .NcFQ_G_deleteRow__icon {
  width: 30px;
  height: 30px;
  color: #fff;
}

.I1Ia_a_container {
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  display: flex;
}

.I1Ia_a_container .I1Ia_a_label {
  font-weight: bold;
}

.I1Ia_a_input {
  background-color: var(--color-primary);
  height: 100%;
  color: #fff;
  min-height: 40px;
  border: none;
  border-radius: 5px;
  outline: none;
  padding: 0 5px;
  font-weight: 600;
}

::-webkit-calendar-picker-indicator {
  cursor: pointer;
  filter: invert();
  border-radius: 3px;
  padding: 5px;
}

.Hn2k8G_modal {
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: #0006;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.Hn2k8G_modal__active {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Hn2k8G_modal__inActive {
  display: none;
}

.Hn2k8G_modalContent {
  min-width: 260px;
  width: auto;
  background-color: #fefefe;
  border: 1px solid #888;
  border-radius: 10px;
  margin: 15% auto;
  padding: 20px;
}

.Hn2k8G_close {
  color: #aaa;
  float: right;
  font-size: var(--font-extra-large);
  font-weight: bold;
}

.Hn2k8G_close:hover, .Hn2k8G_close:focus {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

.Hn2k8G_title {
  color: var(--color-primary);
  width: 100%;
  font-size: var(--font-large);
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  display: flex;
}

._7likaq_formContainer {
  min-width: 800px;
  background-color: #eee;
  border-radius: 10px;
  grid-template-rows: auto;
  grid-template-columns: 150px 150px 150px 300px 100px;
  place-items: start center;
  gap: 30px;
  padding: 20px;
  display: grid;
}

._7likaq_formContainer ._7likaq_element {
  width: 100%;
  justify-content: flex-start;
  align-self: flex-start;
  align-items: center;
  display: flex;
}

._7likaq_buttonContainer {
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

._7likaq_buttonContainer ._7likaq_button {
  width: 50px;
  height: 50px;
  align-self: center;
}

._7likaq_buttonContainer ._7likaq_button ._7likaq_buttonText {
  font-size: 30px;
  font-weight: bolder;
  position: relative;
  top: -5px;
}

._7likaq_rates {
  width: auto;
}

._7likaq_rates__title {
  font-weight: bolder;
}

._7likaq_rates__grid {
  grid-template-columns: 100px 100px 100px;
  gap: 10px;
  display: grid;
}

.iVaYKW_container {
  min-height: var(--body-height);
  background-color: #f5f3f3;
}

.iVaYKW_container .iVaYKW_content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 30px;
  display: flex;
}

.iVaYKW_container .iVaYKW_content .iVaYKW_formContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  display: flex;
}

.iVaYKW_container .iVaYKW_content .iVaYKW_formContainer .iVaYKW_formContent {
  width: 800px;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  display: grid;
}

.iVaYKW_container .iVaYKW_content .iVaYKW_formContainer .iVaYKW_deleteOption {
  flex-direction: column;
  justify-content: start;
  gap: 10px;
  display: flex;
}

.iVaYKW_container .iVaYKW_content .iVaYKW_formContainer .iVaYKW_deleteOption__label {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bolder;
  display: flex;
}

.iVaYKW_container .iVaYKW_content .iVaYKW_formContainer .iVaYKW_deleteOption__button {
  width: 100px;
  height: 38px;
}

.iVaYKW_container .iVaYKW_content .iVaYKW_formContainer .iVaYKW_stations {
  width: 50%;
}

.iVaYKW_container .iVaYKW_content .iVaYKW_formContainer .iVaYKW_buttons {
  width: 500px;
  height: 50px;
  flex-direction: row;
  gap: 30px;
  display: flex;
}

.iVaYKW_contentModal {
  margin: 40px 20px 25px;
}

.iVaYKW_contentModal .iVaYKW_buttons {
  flex-direction: row;
  gap: 20px;
  display: flex;
}

.I1MzDW_table {
  width: 100%;
  border-spacing: 0px 0px;
}

.I1MzDW_tableHeader {
  color: #fff;
  text-transform: uppercase;
  background-color: #fe6b03;
  border-right: 4px solid #fff;
  padding: 20px 50px;
  font-size: 20px;
}

.I1MzDW_tableCell {
  text-align: center;
  border-bottom: 1px solid #d3d3d3;
  align-self: center;
  margin: 0;
  padding: 10px;
  font-size: 20px;
}

.sLS-SG_container {
  width: 100%;
  height: var(--body-height);
  background-color: #80808025;
}

.sLS-SG_tableCell {
  justify-content: center;
  align-items: center;
  display: flex;
}

.sLS-SG_icon {
  height: 40px;
  width: 40px;
  object-fit: contain;
}

.sLS-SG_header {
  background-color: #80808025;
  justify-content: center;
  align-items: center;
  display: flex;
}

.sLS-SG_header .sLS-SG_headerContent {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 30px;
  display: flex;
}

.sLS-SG_header .sLS-SG_headerContent .sLS-SG_buttons {
  width: 600px;
  flex-direction: row;
  gap: 10px;
  display: flex;
}

.sLS-SG_header .sLS-SG_headerContent .sLS-SG_searchBar {
  width: 550px;
}

.sLS-SG_table {
  margin: 0 30px;
  overflow-x: scroll;
}

.sLS-SG_options {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
  display: flex;
}

.sLS-SG_optionIcon {
  height: 25px;
  cursor: pointer;
}

.sLS-SG_optionIcon:hover {
  opacity: .7;
}

.y2WrIq_container {
  width: 100%;
  height: 40px;
  border: 1px solid var(--color-primary);
  background-color: #fff;
  border-radius: 5px;
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.y2WrIq_container .y2WrIq_input {
  width: 60%;
  height: 90%;
  color: var(--color-primary);
  border: none;
  outline: none;
  margin: 0 0 0 10px;
}

.y2WrIq_container .y2WrIq_input::placeholder {
  color: var(--color-primary);
}

.y2WrIq_container .y2WrIq_select {
  width: 30%;
  height: 100%;
}

.y2WrIq_container .y2WrIq_containerIcon {
  width: 10%;
  background-color: var(--color-primary);
  color: #fff;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.y2WrIq_container .y2WrIq_containerIcon .y2WrIq_icon {
  min-width: 20px;
  margin: 15px;
}

.j-_CBa_container {
  width: auto;
  height: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.j-_CBa_container .j-_CBa_content {
  width: auto;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  align-items: center;
  gap: 5px;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.j-_CBa_container .j-_CBa_content .j-_CBa_item {
  width: 40px;
  height: 40px;
}

.j-_CBa_container .j-_CBa_content .j-_CBa_item .j-_CBa_button {
  border: 1px solid var(--color-primary);
  width: 100%;
  height: 100%;
  color: var(--color-primary);
  background-color: #0000;
  border-radius: 5px;
  font-size: 16px;
}

.j-_CBa_container .j-_CBa_content .j-_CBa_item .j-_CBa_button__active {
  background-color: var(--color-primary);
  color: #fff;
}

.j-_CBa_container .j-_CBa_content .j-_CBa_item .j-_CBa_button:disabled {
  color: #666;
  pointer-events: none;
  background-color: #ccc;
  border: 1px solid #999;
}

.j-_CBa_container .j-_CBa_content .j-_CBa_item .j-_CBa_button:hover {
  background-color: var(--color-primary);
  opacity: .8;
  color: #fff;
}

._2fPM8a_container {
  min-height: var(--body-height);
  background-color: #f5f3f3;
  flex-direction: column;
  display: flex;
}

._2fPM8a_container ._2fPM8a_content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 30px 30px 15px;
  display: flex;
}

._2fPM8a_container ._2fPM8a_content ._2fPM8a_table {
  width: 100%;
  height: 500px;
  overflow-x: hidden;
  overflow-y: scroll;
}

._2fPM8a_container ._2fPM8a_button {
  width: 400px;
  height: 40px;
  align-self: center;
}

._2fPM8a_containerModal {
  min-width: 1000px;
  min-height: 350px;
}

._2fPM8a_containerModal ._2fPM8a_formContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-top: 40px;
  display: flex;
}

._2fPM8a_containerModal ._2fPM8a_formContainer ._2fPM8a_formContent {
  width: 800px;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  gap: 30px;
  display: grid;
}

._2fPM8a_containerModal ._2fPM8a_formContainer ._2fPM8a_button {
  width: 200px;
  height: 50px;
  margin-bottom: 20px;
}

._2fPM8a_containerModal ._2fPM8a_formContainer ._2fPM8a_rates {
  grid-column: 1 / 3;
}

._2fPM8a_containerModal ._2fPM8a_formContainer ._2fPM8a_rates__title {
  font-weight: bolder;
}

._2fPM8a_containerModal ._2fPM8a_formContainer ._2fPM8a_rates__grid {
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  display: grid;
}

._2fPM8a_tableCell {
  justify-content: center;
  align-items: center;
  display: flex;
}

._2fPM8a_icon {
  height: 40px;
  width: 40px;
  object-fit: contain;
}

.CWcyqG_checkedCheckbox {
  width: 20px;
  height: 20px;
  min-width: max-content;
  background-color: #ec8d4b;
  border: 3px solid #e87728;
  margin: 20px;
}

.CWcyqG_checkbox {
  width: 20px;
  height: 20px;
  min-width: max-content;
  background-color: #fff;
  border: 3px solid #e87728;
  margin: 20px;
}

.ozoXMW_table {
  width: 100%;
  border-spacing: 0px 0px;
}

.ozoXMW_tableHeader {
  color: #fff;
  text-transform: uppercase;
  background-color: #fe6b03;
  border-right: 4px solid #fff;
  padding: 20px 50px;
  font-size: 20px;
}

.ozoXMW_tableCell {
  text-align: center;
  border-bottom: 1px solid #d3d3d3;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin: 0;
  padding: 10px;
  font-size: 20px;
}

.ozoXMW_icon {
  height: 30px;
  cursor: pointer;
}

.ozoXMW_icon:hover {
  opacity: .7;
}

.ozoXMW_psContainer {
  grid-template-rows: 1fr 1fr auto;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-self: center;
  align-items: center;
  gap: 20px;
  display: grid;
}

.ozoXMW_psContainer .ozoXMW_psItem {
  width: auto;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  align-items: center;
  display: flex;
}

.ozoXMW_psContainer .ozoXMW_psItem__section {
  width: 90px;
  justify-content: start;
  align-self: center;
  display: flex;
}

.awV2lq_tableCell {
  justify-content: center;
  align-items: center;
  display: flex;
}

.awV2lq_icon {
  height: 40px;
  width: 40px;
  object-fit: contain;
}

.awV2lq_container {
  width: 100%;
  height: var(--body-height);
  background-color: #80808025;
}

.awV2lq_container .awV2lq_content {
  flex-direction: column;
  gap: 40px;
  padding: 30px;
  display: flex;
}

.awV2lq_container .awV2lq_content .awV2lq_tableContainer {
  height: 100%;
  width: 100%;
}

.awV2lq_container .awV2lq_content .awV2lq_tableContainer .awV2lq_tableOptions {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.awV2lq_container .awV2lq_content .awV2lq_tableContainer .awV2lq_tableOptions .awV2lq_addButton {
  width: 50px;
  height: 50px;
  border: 2.5px solid var(--color-primary);
  color: var(--color-primary);
  cursor: pointer;
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  display: flex;
}

.awV2lq_container .awV2lq_content .awV2lq_tableContainer .awV2lq_tableOptions .awV2lq_addButton:hover {
  color: #fff;
  background-color: var(--color-primary);
}

.awV2lq_container .awV2lq_content .awV2lq_tableContainer .awV2lq_tableOptions .awV2lq_searchBar {
  width: 700px;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  display: flex;
}

.awV2lq_container .awV2lq_content .awV2lq_tableContainer .awV2lq_tableOptions .awV2lq_searchBar__input {
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  width: 250px;
  height: 45px;
  border-radius: 5px 0 0 5px;
  outline: none;
  padding: 0 10px;
  font-size: 20px;
}

.awV2lq_container .awV2lq_content .awV2lq_tableContainer .awV2lq_tableOptions .awV2lq_searchBar__input::placeholder {
  color: var(--color-primary);
}

.awV2lq_container .awV2lq_content .awV2lq_tableContainer .awV2lq_tableOptions .awV2lq_searchBar__button {
  width: 50px;
  height: 50px;
  background-color: var(--color-primary);
  color: #fff;
  cursor: pointer;
  border-radius: 0 5px 5px 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.awV2lq_container .awV2lq_content .awV2lq_tableContainer .awV2lq_tableOptions .awV2lq_searchBar__button--icon {
  width: 20px;
  height: 20px;
  margin: 15px;
}

.awV2lq_container .awV2lq_content .awV2lq_tableContainer .awV2lq_table {
  margin-top: 20px;
  overflow-x: scroll;
}

.awV2lq_modalContent {
  min-width: 300px;
  flex-direction: column;
  gap: 10px;
  margin: 40px;
  display: flex;
}

.zdsLPq_table {
  width: 100%;
  border-spacing: 0px 0px;
}

.zdsLPq_tableHeader {
  color: #fff;
  text-transform: uppercase;
  background-color: #fe6b03;
  border-right: 4px solid #fff;
  padding: 20px 50px;
  font-size: 20px;
}

.zdsLPq_tableCell {
  text-align: center;
  border-bottom: 1px solid #d3d3d3;
  align-self: center;
  margin: 0;
  padding: 10px;
  font-size: 20px;
}

.zdsLPq_icon {
  height: 20px;
  cursor: pointer;
}

.zdsLPq_icon:hover {
  opacity: .7;
}

.dQceHa_container {
  min-height: var(--body-height);
  background-color: #f5f3f3;
  flex-direction: column;
  display: flex;
}

.dQceHa_container .dQceHa_content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 30px 30px 15px;
  display: flex;
}

.dQceHa_container .dQceHa_content .dQceHa_table {
  width: 100%;
  height: 500px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.dQceHa_container .dQceHa_button {
  width: 350px;
  height: 40px;
  align-self: center;
}

.dQceHa_contentModal {
  margin: 40px 20px 25px;
}

.dQceHa_contentModal .dQceHa_buttons {
  flex-direction: row;
  gap: 20px;
  display: flex;
}

.dQceHa_tableCell {
  justify-content: center;
  align-items: center;
  display: flex;
}

.dQceHa_icon {
  height: 40px;
  width: 40px;
  object-fit: contain;
}

._1hZYga_headerContainer {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

._1hZYga_name {
  color: #777;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 70px;
  font-size: 40px;
}

._1hZYga_street {
  color: #777;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 70px;
  font-size: 20px;
  display: flex;
}

._1hZYga_locationIcon {
  height: 35px;
  margin-right: 5px;
}

._1hZYga_navContainer {
  display: flex;
}

._1hZYga_navItem {
  color: #fff;
  height: 15px;
  width: 70px;
  background-color: #fe6b03;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  padding: 20px 80px;
  font-size: 22px;
  display: flex;
}

.JUs5Wa_mainContainer {
  grid-template-rows: repeat(auto-fill, 100px);
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  padding: 0 50px;
  display: grid;
}

.JUs5Wa_documentationElement {
  max-width: 150px;
  flex-direction: column;
  margin: 0 40px;
  display: flex;
}

.JUs5Wa_documentationElement:hover {
  cursor: pointer;
}

.JUs5Wa_icon {
  height: 130px;
  object-fit: contain;
}

.JUs5Wa_documentationText {
  color: #777;
  text-align: center;
  font-size: 22px;
}

.JUs5Wa_addIcon {
  height: 70px;
  object-fit: contain;
  align-self: center;
  margin-left: 20px;
}

.JUs5Wa_addIcon:hover {
  cursor: pointer;
}

.JUs5Wa_fileTypeContainer {
  min-height: 200px;
  max-height: 200px;
  height: 100%;
  background-color: #f5bb78;
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  margin: 0 15px;
  padding: 30px 50px;
  display: flex;
  overflow: hidden;
}

.JUs5Wa_fileTypeContainer__deleteOption {
  color: #f5f5f5;
  width: 25px;
  height: 25px;
  text-align: center;
  cursor: pointer;
  background-color: tomato;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  display: flex;
  position: relative;
  top: 35px;
  left: calc(100% - 50px);
}

.JUs5Wa_fileTypeIcon {
  height: 100px;
  object-fit: contain;
  cursor: pointer;
}

.JUs5Wa_fileTypeText {
  text-transform: uppercase;
  max-width: 200px;
  min-height: 80px;
  max-height: 80px;
  margin-top: 30px;
  font-size: 1.2vw;
  font-weight: bold;
  overflow: hidden;
}

.JUs5Wa_uploadContainer {
  border: 2px dashed;
  flex-direction: column;
  align-items: center;
  margin: 60px;
  padding: 60px;
  display: flex;
}

.JUs5Wa_uploadIcon {
  height: 150px;
  object-fit: contain;
}

.JUs5Wa_uploadButton {
  text-transform: uppercase;
  background-color: #e8721f;
  border-radius: 5px;
  padding: 10px;
  font-size: 18px;
}

.JUs5Wa_uploadButton:hover {
  cursor: pointer;
}

.n2B-bq_tableCell {
  justify-content: center;
  align-items: center;
  display: flex;
}

.n2B-bq_icon {
  height: 30px;
  width: 30px;
  object-fit: contain;
  color: orange;
}

.n2B-bq_icon__active {
  color: green;
}

.n2B-bq_icon__inactive {
  color: red;
}

.n2B-bq_icon__unreachable {
  color: orange;
}

.XzRGBW_mainContainer {
  margin: 0 80px 50px;
}

.XzRGBW_titleContainer {
  text-transform: uppercase;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.XzRGBW_title {
  color: #777;
  margin: 0;
  font-size: 22px;
  font-weight: bold;
}

.XzRGBW_newAlarmButton {
  color: #fff;
  background-color: #fe6b03;
  border-width: 0;
  margin-top: 30px;
  padding: 10px;
}

.XzRGBW_table {
  align-self: center;
  margin: 30px 0;
}

.XzRGBW_tableHeaderRow {
  flex-direction: row;
  justify-content: space-around;
  display: flex;
}

.XzRGBW_headerCell {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  background-color: #fe6b03;
  flex: 1;
  margin-right: 1px;
  padding: 10px 0;
  font-size: 12px;
  font-weight: bold;
}

.XzRGBW_tableBodyRow {
  border: 1px solid #fe6b03;
  border-top: 0;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  display: flex;
}

.XzRGBW_bodyCell, .XzRGBW_updateCell {
  text-align: center;
  flex: 1;
  padding: 5px;
}

.XzRGBW_updateCell:hover {
  cursor: pointer;
}

.XzRGBW_historicTable {
  width: 50%;
  align-self: center;
  margin: 30px 0;
}

.XzRGBW_onOffIcon, .XzRGBW_editIcon {
  height: 35px;
}

.XzRGBW_noStationAlarmsHistoryText {
  align-self: center;
  margin: 40px;
}

.n9sKHW_stationDataContainer {
  justify-content: center;
  margin: 0 60px 100px;
  display: flex;
}

.n9sKHW_stationGeneralDataContainer {
  width: -webkit-fill-available;
  align-self: flex-start;
  padding: 20px;
}

.n9sKHW_stationGeneralDataText {
  color: #777;
  align-items: center;
  font-size: 22px;
  display: flex;
}

.n9sKHW_stationGeneralDataIcon {
  height: 35px;
  margin-right: 5px;
}

.n9sKHW_stationDataItem {
  border: 2px solid #fe6b03;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  padding: 25px;
  display: flex;
}

.n9sKHW_stationDataLeftContainer {
  flex: 1;
}

.n9sKHW_stationDataLeftRowContainer {
  flex-direction: row;
  flex: 1;
  display: flex;
}

.n9sKHW_title {
  color: #777;
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 30px;
  font-size: 28px;
  font-weight: bold;
}

.n9sKHW_icon {
  height: 130px;
}

.n9sKHW_bigNumber {
  color: #777;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  font-size: 30px;
}

.n9sKHW_image {
  height: 400px;
  object-fit: contain;
  flex: 1;
}

.n9sKHW_amortizedCapitalRow {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.n9sKHW_amortizedCapitalItem {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px;
  display: flex;
}

.n9sKHW_amortizedCapitalLegendRow {
  width: -webkit-fill-available;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
  display: flex;
}

.n9sKHW_amortizedCapitalLegendChip {
  height: 5px;
  width: 20px;
  background-color: #fe6b03;
  border-radius: 20px;
  margin-right: 5px;
  padding: 5px;
}

.n9sKHW_amortizedCapitalLegendText {
  color: #777;
  text-transform: uppercase;
  font-size: 15px;
}

.n9sKHW_serviceTypeContainer {
  flex-direction: row;
  display: flex;
}

.n9sKHW_premiumServiceContainer {
  color: #fe6b03;
  border: 2px solid #fe6b03;
  margin-right: 10px;
  padding: 5px 15px;
  font-weight: bold;
}

.n9sKHW_basicServiceContainer {
  border: 2px solid #fe6b03;
  margin: 0 5px;
  padding: 5px 15px;
}

.n9sKHW_footer {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.n9sKHW_footer__text {
  width: 60%;
  text-align: center;
  margin: 0 0 40px;
}

._7ZVkaq_selectedValueContainer {
  color: #fff;
  text-transform: uppercase;
  max-height: 40px;
  white-space: nowrap;
  background-color: #696969;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
}

._7ZVkaq_selectedValueContainer:hover {
  cursor: pointer;
}

._7ZVkaq_icon {
  width: 20px;
  height: 20px;
  align-self: center;
  margin-left: 20px;
}

._7ZVkaq_optionsContainer {
  z-index: 100;
  position: absolute;
}

._7ZVkaq_optionItem {
  text-transform: uppercase;
  color: #fff;
  background-color: #696969;
  padding: 10px 20px;
  font-size: 20px;
}

._7ZVkaq_optionItem:hover {
  cursor: pointer;
}

.NSYbqG_container {
  width: -webkit-fill-available;
  border: 1px solid #fe6b03;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  display: flex;
}

.NSYbqG_titleRow {
  width: -webkit-fill-available;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 50px;
  display: flex;
}

.NSYbqG_title {
  color: #777;
  text-transform: uppercase;
  font-size: 30px;
}

.j0mTAa_mainContainer {
  margin: 0 80px;
}

.j0mTAa_title {
  color: #777;
  text-transform: uppercase;
  margin: 0;
  font-size: 22px;
  font-weight: bold;
}

.j0mTAa_table {
  align-self: center;
  margin: 30px 0;
}

.j0mTAa_tableHeaderRow {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.j0mTAa_headerCell {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  background-color: #fe6b03;
  flex: 1;
  margin-right: 1px;
  padding: 10px 0;
  font-size: 12px;
  font-weight: bold;
}

.j0mTAa_tableBodyRow {
  border: 1px solid #fe6b03;
  border-top: 0;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  display: flex;
}

.j0mTAa_bodyCell {
  text-align: center;
  flex: 1;
  align-self: flex-end;
  padding: 10px 0;
}

.j0mTAa_spinnerContainer {
  border: 1px solid #fe6b03;
  justify-content: center;
  display: flex;
}

.j0mTAa_moreInfoContainer {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 50px;
  display: flex;
}

.j0mTAa_moreInfoTable {
  flex: 1;
  margin-top: 20px;
  margin-right: 40px;
}

.j0mTAa_moreInfoTableBodyRow {
  border: 1px solid #fe6b03;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  font-size: 14px;
  display: flex;
}

.Kc0y9a_stationDetailsContainer {
  justify-content: center;
  align-items: center;
  margin: 0 60px;
  display: flex;
}

.cU9IeG_stationDetailsItem {
  height: 450px;
  border: 2px solid #fe6b03;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin: 5px;
  padding: 30px;
  display: flex;
}

.cU9IeG_stationDetailsItemTitleRow {
  width: -webkit-fill-available;
  flex-direction: row;
  justify-content: space-around;
  align-items: baseline;
  display: flex;
}

.cU9IeG_stationDetailsItemTitle {
  color: #777;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: bold;
}

.B5I-wG_stationDetailsItem {
  height: 450px;
  border: 2px solid #fe6b03;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin: 5px;
  padding: 30px;
  display: flex;
}

.B5I-wG_stationDetailsItemTitleRow {
  width: -webkit-fill-available;
  flex-direction: row;
  justify-content: space-around;
  align-items: baseline;
  display: flex;
}

.B5I-wG_stationDetailsItemTitle {
  color: #777;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: bold;
}

.xGPxvW_stationDetailsItem {
  height: 550px;
  border: 2px solid #fe6b03;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin: 5px;
  padding: 30px;
  display: flex;
}

.xGPxvW_stationDetailsItemTitleRow {
  width: -webkit-fill-available;
  flex-direction: row;
  justify-content: space-around;
  align-items: baseline;
  display: flex;
}

.xGPxvW_stationDetailsItemTitle {
  color: #777;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: bold;
}

.xGPxvW_image {
  width: 50%;
  object-fit: contain;
  width: "100%";
  height: 385px;
}

.xGPxvW_emsolarLogoContainer {
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

.xGPxvW_emsolarLogo {
  height: 85px;
  width: 90px;
  padding-right: 120px;
}

.xGPxvW_emsolarLogo:hover {
  cursor: pointer;
}

.d1wRvW_stationDetailsItem {
  height: 550px;
  border: 2px solid #fe6b03;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
  padding: 30px;
  display: flex;
}

.d1wRvW_stationDetailsItemTitle {
  color: #777;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: bold;
}

.d1wRvW_image {
  width: 50%;
  object-fit: contain;
  width: "100%";
  height: 165px;
}

.d1wRvW_investmentRecuperationText {
  color: #777;
  text-align: center;
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: bold;
}

.xz57gW_stationDetailsItem {
  height: max-content;
  border: 2px solid #fe6b03;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin: 5px;
  padding: 30px;
  display: flex;
}

.xz57gW_stationDetailsItemTitle {
  color: #777;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: bold;
}

.xz57gW_image {
  object-fit: contain;
  width: 75%;
  flex: 1;
}

.xz57gW_investmentRecuperationText {
  color: #777;
  text-align: center;
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: bold;
}

.mXyOFW_daySelectorContainer {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.mXyOFW_dayUnselectedItem {
  width: 20px;
  text-align: center;
  color: #777;
  background-color: #fcfcfc;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 2px;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
}

.mXyOFW_dayUnselectedItem:hover {
  cursor: pointer;
}

.mXyOFW_daySelectedItem {
  color: #fff;
  width: 20px;
  text-align: center;
  background-color: #f9ad0b;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 2px;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
}

.mXyOFW_daySelectedItem:hover {
  cursor: pointer;
}

.GoP_Xa_timeSelectorContainer {
  min-height: 40px;
  min-width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 400px;
  display: flex;
}

.GoP_Xa_label {
  color: #777;
  white-space: nowrap;
  padding: 0 15px;
  font-size: 22px;
}

.GoP_Xa_is24HoursContainer {
  min-width: 100px;
  white-space: nowrap;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.GoP_Xa_is24HoursContainer:hover {
  cursor: pointer;
}

.GoP_Xa_activeDot {
  width: 20px;
  height: 20px;
  background-color: #f1a263;
  border: 1px solid #7c6c50;
  border-radius: 20px;
}

.GoP_Xa_inactiveDot {
  width: 20px;
  height: 20px;
  background-color: #f4f4f4;
  border: 1px solid #bfbfbf;
  border-radius: 20px;
}

.GoP_Xa_input {
  background-color: #fcfcfc;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 15px;
}

.EIquUG_mainContainer {
  flex-direction: column;
  margin: 0 70px;
  display: flex;
}

.EIquUG_labelInputContainer {
  width: 40%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  display: flex;
}

.EIquUG_label {
  max-width: 400px;
  font-size: 22px;
}

.EIquUG_input {
  width: 200px;
  height: 40px;
  text-align: start;
  background-color: #fcfcfc;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0 10px;
}

.EIquUG_button {
  color: #fff;
  width: 100px;
  text-transform: uppercase;
  background-color: #fe6b03;
  border-width: 0;
  margin-top: 30px;
  padding: 10px;
}

button:hover {
  cursor: pointer;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.EIquUG_selectInput {
  width: 220px;
  height: 50px;
  text-align: center;
  background-color: #fcfcfc;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0;
}

.EIquUG_error {
  color: red;
}

.n0Ar_q_mainContainer {
  width: 70vw;
  margin: 0 80px;
}

.n0Ar_q_title {
  color: #777;
  margin: 0 0 20px;
  font-size: 22px;
  font-weight: bold;
}

.n0Ar_q_invertersHeaderContainer {
  justify-content: space-between;
  display: flex;
}

.n0Ar_q_headerCell {
  text-align: center;
  color: #fff;
  background-color: #fe6b03;
  flex: 1;
  margin-right: 1px;
  padding: 10px 0;
  font-size: 14px;
  font-weight: bold;
}

.n0Ar_q_bodyRow {
  border: 1px solid #fe6b03;
  border-top: 0;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.n0Ar_q_bodyCell {
  text-align: center;
  color: #777;
  background-color: #fff;
  flex: 1;
  margin-right: 1px;
  padding: 10px 0;
  font-size: 16px;
}

.n0Ar_q_inverterContainer {
  text-transform: uppercase;
  text-align: center;
  border: 1px solid #fe6b03;
  border-top: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  font-size: 20px;
  display: flex;
}

.n0Ar_q_inverterContainer:hover {
  cursor: pointer;
}

.n0Ar_q_icon {
  height: 30px;
  width: 30px;
  object-fit: contain;
  position: relative;
  left: 15vw;
}

.Fap7eW_container {
  background-color: #fff;
  border-radius: 15px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 15px;
  display: flex;
}

.Fap7eW_icon {
  height: 30px;
  padding: 10px;
}

.Fap7eW_input {
  color: #e66c23;
  border: none;
  margin: 10px;
  font-size: 30px;
}

.Fap7eW_input::placeholder {
  color: #e66c23;
  opacity: 1;
}

.Fap7eW_input:focus {
  outline: none;
}

.NixY9G_loginContainer {
  min-height: 90vh;
  background-color: #001533;
  border-width: 10px;
  border-color: #0ff;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.NixY9G_title {
  color: #fff;
  font-size: 60px;
}

.NixY9G_error {
  color: red;
  font-size: 20px;
}

.NixY9G_button {
  color: #fff;
  background-color: var(--color-primary);
  border-width: 0;
  margin-top: 30px;
  padding: 10px;
}

.NixY9G_recoverPassword {
  color: #e9e8e8;
  font-size: 20px;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.NixY9G_recoverPassword:after {
  content: "";
  height: 1px;
  width: 0;
  margin: auto;
  transition: all .8s;
  display: block;
}

.NixY9G_recoverPassword:hover:after {
  width: 100%;
  background: #cecece;
}

button:hover {
  cursor: pointer;
}

.s06fGG_container {
  min-height: 90vh;
  background-color: #001533;
  border-width: 10px;
  border-color: #0ff;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.s06fGG_title {
  color: #fff;
  font-size: 60px;
}

.s06fGG_button {
  color: #fff;
  background-color: var(--color-primary);
  border-width: 0;
  margin-top: 30px;
  padding: 10px;
}

.s06fGG_contentModal {
  width: 400px;
  margin: 20px;
}

.s06fGG_contentModal .s06fGG_buttons {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: flex;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient( to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55 );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index) px);
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: pointer;
  direction: ltr;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  width: 20px;
  flex-shrink: 0;
  margin-inline-end: 10px;
  display: flex;
}

.Toastify--animate {
  animation-duration: .7s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light, .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear forwards;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  animation: Toastify__spin .65s linear infinite;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.body {
  font-family: SegoeUI, sans-serif;
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

:root {
  --color-primary: #fe6b03;
  --color-primary-low: #fe6c0336;
  --color-secondary: #15ab92;
  --color-secondary-low: #81e7df;
  --color-terciary: #716584;
  --color-terciary-low: #aea2c3;
  --color-quaternary: #ffd200;
  --color-quaternary: #fff0b3;
  --color-white: white;
  --color-black: black;
}

@font-face {
  font-family: SegoeUI;
  src: url("segoeui.2640d4a3.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: SegoeUI;
  src: url("segoeuib.dd678935.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

:root {
  --nav-height: 10vh;
  --body-height: 85vh;
  --footer-height-user: 7vh;
  --font-small: 12px;
  --font-normal: 16px;
  --font-medium: 20px;
  --font-large: 25px;
  --font-extra-large: 30px;
  --border-radius-small: 10px;
  --border-radius-normal: 15px;
  --border-radius-medium: 20px;
  --border-radius-large: 30px;
  --border-radius-larger: 40px;
  --border-radius-extra-large: 50px;
  --z-level-1: 5;
  --z-level-2: 10;
  --z-level-3: 15;
  --z-level-4: 20;
  --z-level-5: 25;
}

a {
  color: #000;
  text-decoration: none;
}

/*# sourceMappingURL=index.4d9a8baf.css.map */
