:root {
    --color-primary: #fe6b03;
    --color-primary-low: #fe6c0336;
    --color-secondary: #15ab92;
    --color-secondary-low: #81e7df;
    --color-terciary: #716584;
    --color-terciary-low: #aea2c3;
    --color-quaternary: #ffd200;
    --color-quaternary: #fff0b3;

    --color-white: white;
    --color-black: black;
}
